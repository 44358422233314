import React from 'react';
import PropTypes from 'prop-types';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

function GoToMercadoShops({ link }) {
  return (
    <OutboundLink
      className="uk-button uk-button-primary uk-button-large"
      href={link}
      rel="noopener noreferrer"
      target="_blank"
    >
      Comprar ahora
    </OutboundLink>
  );
}

GoToMercadoShops.propTypes = {
  link: PropTypes.string.isRequired,
};

export default GoToMercadoShops;
