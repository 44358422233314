import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';

const ZoomInImg = styled.a`
  cursor: zoom-in;
`;

function Slideshow({ imgList, className }) {
  return (
    <div
      className={className}
      uk-slideshow="autoplay: true; autoplay-interval: 7000;"
    >
      <div className="uk-position-relative uk-visible-toggle uk-dark">
        <ul className="uk-slideshow-items" uk-lightbox="">
          {imgList.map((img, i) => {
            return (
              <li key={i}>
                <ZoomInImg href={img.childImageSharp.fluid.src}>
                  <Img fluid={img.childImageSharp.fluid} alt="" uk-cover="" />
                </ZoomInImg>
              </li>
            );
          })}
        </ul>
        <a
          className="uk-position-center-left uk-position-small uk-hidden-hover"
          href="#previous"
          uk-slidenav-previous=""
          uk-slideshow-item="previous"
          aria-label="Anterior"
        >
          {' '}
        </a>
        <a
          className="uk-position-center-right uk-position-small uk-hidden-hover"
          href="#next"
          uk-slidenav-next=""
          uk-slideshow-item="next"
          aria-label="Siguiente"
        >
          {' '}
        </a>
      </div>
      <div className="uk-flex uk-flex-center uk-margin">
        <ul className="uk-slideshow-nav uk-dotnav" />
      </div>
    </div>
  );
}

Slideshow.defaultProps = {
  className: '',
};

Slideshow.propTypes = {
  className: PropTypes.string,
  imgList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Slideshow;
