import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Section from '../components/Section';
import Slideshow from '../components/Slideshow';
import Faq from '../components/Faq';
import GoToMercadoShops from '../components/Cart/GoToMercadoShops';

const StyledHeader = styled.h2`
  font-size: 2rem;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

function ProductTemplate({ data }) {
  const { productsYaml } = data;
  return (
    <Layout>
      <SEO title={productsYaml.title} />
      <Section
        title={productsYaml.title}
        smallTitle
        sectionClass="uk-section-xsmall"
        containerClass="uk-container-xsmall"
      >
        <div className="uk-grid">
          <div className="uk-width-1-1 uk-margin-medium-bottom">
            <Slideshow imgList={productsYaml.gallery.slice(1)} />

            <Container>
              <GoToMercadoShops lg link={productsYaml.store_link} />
            </Container>

            <StyledHeader>Descripción</StyledHeader>
            <div
              dangerouslySetInnerHTML={{
                __html: productsYaml.fields.descriptionHTML,
              }}
            />

            <StyledHeader>Instructivo</StyledHeader>
            <p>
              Puedes descargar el instructivo mediante el siguiente{' '}
              <a
                href={`/download/${productsYaml.instructive}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                enlace
              </a>
              .
            </p>
            <StyledHeader>Preguntas frecuentes</StyledHeader>
            <Faq faq={productsYaml.fields.faqHTML} />
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export default ProductTemplate;

export const query = graphql`
  query ProductQuery($slug: String!) {
    productsYaml(fields: { slug: { eq: $slug } }) {
      item_id
      title
      variants
      instructive
      store_link
      fields {
        descriptionHTML
        faqHTML {
          question
          answer
        }
      }
      gallery {
        childImageSharp {
          fluid(quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
