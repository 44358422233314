import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretSquareRight,
  faCaretSquareDown,
} from '@fortawesome/free-solid-svg-icons';

import { colors } from '../constants';

const Title = styled.a`
  font-size: 1.25rem;
  color: ${colors.red};
  &:hover,
  &:focus {
    color: ${colors.red};
  }

  &.uk-accordion-title {
    ::before {
      background-image: none;
    }
  }
  .uk-open > &.uk-acordion-title {
    ::before {
      background-image: none;
    }
  }
`;

const CaretRight = () => (
  <FontAwesomeIcon icon={faCaretSquareRight} size="xs" />
);
const CaretDown = () => <FontAwesomeIcon icon={faCaretSquareDown} size="xs" />;

function Question({ question, answer }) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <Title
        className="uk-accordion-title"
        href="#qa"
        onClick={() => setOpen(!open)}
      >
        {open ? <CaretDown /> : <CaretRight />} {question}
      </Title>
      <div
        className="uk-accordion-content"
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </React.Fragment>
  );
}

function Faq({ faq }) {
  return (
    <ul className="uk-list-divider uk-list-large" uk-accordion="multiple: true">
      {faq.map(({ question, answer }, i) => {
        return (
          <li key={i}>
            <Question question={question} answer={answer} />
          </li>
        );
      })}
    </ul>
  );
}

Faq.propTypes = {
  faq: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default Faq;
